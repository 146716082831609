import React from 'react'
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ element, children, isAuthenticated }) => {
    if (!isAuthenticated) {
        return <Navigate to="/auth/signin" />;
    }

    return <> {element || children} </>;
}

export default AuthGuard
