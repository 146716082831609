import React from 'react'
import { Navigate } from 'react-router-dom';

const UnAuthGuard = ({ element, children, isAuthenticated }) => {
    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <> {element || children} </>;
}

export default UnAuthGuard
