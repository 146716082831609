import React, { createContext, useContext, useState, useEffect } from 'react';
import customAxios from './utils/customAxios';

import { PATH_AUTH } from './routes/paths';

// Create the AuthContext to manage authentication state
const AuthContext = createContext();

// Custom hook to access the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                setUser(token);
                // Make an authenticated API request to get user data
                await customAxios.get('/account/info')
                    .then(resObj => {
                        if (resObj.data.code === 200) {
                            // console.log(resObj.data)
                        } else {
                            console.log(resObj.data.data)
                            localStorage.removeItem('token');
                            window.location.href = PATH_AUTH.signIn;
                        }
                    }).catch(err => {
                        console.log(err.message)
                    });
            }
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);



    const logout = () => {
        // removeCookie('authToken');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
