

function Header() {
    return (
        <div className="nk-header nk-header-fixed">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-header-logo ms-n1">
                        <div className="nk-sidebar-toggle me-1">
                            <button className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none active">
                                <em className="icon ni ni-menu"> </em>
                            </button>
                            <button className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex">
                                <em className="icon ni ni-menu"></em>
                            </button>
                        </div>
                        <a href="https://copygen.themenio.com/dashboard/index.html" className="logo-link">
                            <div className="logo-wrap">
                                <img className="logo-img logo-light" src="/assets/images/logo.png" alt="" />
                                <img className="logo-img logo-dark" src="/assets/images/logo-dark.png" alt="" />
                                <img className="logo-img logo-icon" src="/assets/images/logo-icon.png" alt="" />
                            </div>
                        </a>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav ms-2">
                            <li className="dropdown d-inline-flex"><a data-bs-toggle="dropdown"
                                className="d-inline-flex" href="#">
                                <div className="media media-md media-circle media-middle text-bg-primary"><img
                                    src="https://copygen.themenio.com/dashboard/images/avatar/a.png" />
                                </div>
                            </a>
                                <div className="dropdown-menu dropdown-menu-md rounded-3">
                                    <div className="dropdown-content py-3">
                                        <div className="border border-light rounded-3">
                                            <div
                                                className="px-3 py-2 bg-white border-bottom border-light rounded-top-3">
                                                <div
                                                    className="d-flex flex-wrap align-items-center justify-content-between">
                                                    <h6 className="lead-text">Free Plan</h6><a
                                                        className="link link-primary" href="#"><em
                                                            className="ni ni-spark-fill icon text-warning"></em><span>Upgrade</span></a>
                                                </div>
                                                <div className="progress progress-md">
                                                    <div className="progress-bar" data-progress="25%"></div>
                                                </div>
                                                <h6 className="lead-text mt-2">1,360 <span className="text-light">words
                                                    left</span></h6>
                                            </div><a
                                                className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
                                                href="profile.html">
                                                <div className="media-group">
                                                    <div
                                                        className="media media-sm media-middle media-circle text-bg-primary">
                                                        <img
                                                            src="/assets/images/user.png" />
                                                    </div>
                                                    <div className="media-text">
                                                        <h6 className="fs-6 mb-0">Abubakar Sadik</h6><span
                                                            className="text-light fs-7">abubakar@gmail.com</span>
                                                    </div><em
                                                        className="icon ni ni-chevron-right ms-auto ps-1"></em>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;