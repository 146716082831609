import axios from 'axios';
// config
import { HOST_API } from '../config';

const customAxios = axios.create({
    // Your custom configuration options go here
    baseURL: HOST_API, // For example, setting a default base URL
    timeout: 10000, // For example, setting a default timeout
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // For example, setting default headers
        'Content-Type': 'application/json',
    },
});

export default customAxios;