import React from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';

function EditPost() {

    return (
        <div className="nk-main">
            <Sidebar />
            <div className="nk-wrap">
                <Header />
                <div className="nk-content nk-content-empty">
                    <div className="nk-editor">
                        <div className="nk-editor-header">
                            <div className="nk-editor-title">
                                <h4 className="me-3 mb-0 line-clamp-1">2023-02-03 Untitled</h4>
                                <ul className="d-inline-flex align-item-center">
                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                        className="icon ni ni-pen"></em></button></li>
                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                        className="icon ni ni-star"></em></button></li>
                                    <li className="d-xl-none me-n1">
                                        <div className="dropdown"><button className="btn btn-sm btn-icon btn-zoom" type="button"
                                            data-bs-toggle="dropdown"><em
                                                className="icon ni ni-menu-alt-r"></em></button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Action</a></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-editor-tools d-none d-xl-flex">
                                <ul className="d-inline-flex gap gx-3 gx-lg-4 pe-4 pe-lg-5">
                                    <li><span className="sub-text text-nowrap">Words <span
                                        className="text-dark">25</span></span></li>
                                    <li><span className="sub-text text-nowrap">Characters <span
                                        className="text-dark">84</span></span></li>
                                </ul>
                                <ul className="d-inline-flex gap gx-3">
                                    <li>
                                        <div className="dropdown"><button className="btn btn-md btn-light rounded-pill"
                                            type="button" data-bs-toggle="dropdown"><span>Export</span><em
                                                className="icon ni ni-chevron-down"></em></button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Action</a></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><button className="btn btn-md btn-primary rounded-pill" type="button"> Save
                                    </button></li>
                                </ul>
                            </div>
                        </div>
                        <div className="nk-editor-main">
                            <div className="nk-editor-base">
                                <ul className="nav nav-tabs nav-sm nav-tabs-s1 px-3">
                                    <li className="nav-item"><button className="nav-link active" type="button"
                                        data-bs-toggle="tab" data-bs-target="#AI-Writer">AI Writer</button></li>
                                    <li className="nav-item"><button className="nav-link" type="button" data-bs-toggle="tab"
                                        data-bs-target="#History">History</button></li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="AI-Writer">
                                        <div className="p-4 ">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        className="media media-xs media-middle media-circle text-primary bg-primary bg-opacity-20">
                                                        <em className="icon ni ni-pen-fill"></em></div>
                                                    <h5 className="fs-14px fw-normal ms-2">Social Media Post</h5>
                                                </div><a href="#" className="link">Edit Prompt</a>
                                            </div>
                                            <div className="card bg-lighter shadow-none mt-3">
                                                <div className="p-3">
                                                    <p className="small text-dark">Write a Facebook post about openai that
                                                        revolutionizes the world through the power of data.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-4 border-top border-light">
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-1">
                                                                <div className="text-primary fs-12px">Variant 1</div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                                                        className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPostNew01"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="small text-dark" id="SocialMediaPostNew01">Hey
                                                                everyone! Have you met ChatGPT? As an AI language model,
                                                                I'm trained to answer your questions and have a
                                                                conversation with you! Ask me anything, and let's get
                                                                chatting! 😊</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>42 Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-1">
                                                                <div className="text-primary fs-12px">Variant 2</div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                                                        className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPostNew02"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="small text-dark" id="SocialMediaPostNew02">Are you
                                                                tired of Googling for answers? Meet ChatGPT - your
                                                                one-stop solution for all your queries! I'm an AI
                                                                language model trained to provide you with accurate and
                                                                informative responses. Try me out! 💬</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>42 Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-1">
                                                                <div className="text-primary fs-12px">Variant 3</div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                                                        className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPostNew03"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="small text-dark" id="SocialMediaPostNew03">OpenAI
                                                                is changing the game for data-driven innovation. With
                                                                its cutting-edge technology, we can harness the power of
                                                                data in ways we never thought possible.</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>42 Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="History">
                                        <div className="px-3 py-4">
                                            <div className="d-flex flex-wrap justify-content-between mb-3">
                                                <h6 className="mb-0 me-3">Generation History</h6>
                                                <div className="fs-11px"><span className="text-dark">1763</span> words left. <a
                                                    href="#">Go Unlimited</a></div>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-2">
                                                                <div
                                                                    className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                    <em className="icon ni ni-pen-fill"></em><span>Social
                                                                        Media Post</span></div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                                                        className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPost01"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-dark" id="SocialMediaPost01">Hey everyone!
                                                                Have you met ChatGPT? As an AI language model, I'm
                                                                trained to answer your questions and have a conversation
                                                                with you! Ask me anything, and let's get chatting! 😊
                                                            </p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>Feb 23, 2023 &nbsp; 05:23 PM</span><span>42
                                                                    Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-2">
                                                                <div
                                                                    className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                    <em className="icon ni ni-pen-fill"></em><span>Social
                                                                        Media Post</span></div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                                                        className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPost02"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-dark" id="SocialMediaPost02">Are you tired of
                                                                Googling for answers? Meet ChatGPT - your one-stop
                                                                solution for all your queries! I'm an AI language model
                                                                trained to provide you with accurate and informative
                                                                responses. Try me out! 💬</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>Feb 23, 2023 &nbsp; 05:23 PM</span><span>42
                                                                    Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-2">
                                                                <div
                                                                    className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                    <em className="icon ni ni-pen-fill"></em><span>Social
                                                                        Media Post</span></div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                                                        className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPost03"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-dark" id="SocialMediaPost03">OpenAI is
                                                                changing the game for data-driven innovation. With its
                                                                cutting-edge technology, we can harness the power of
                                                                data in ways we never thought possible.</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>Feb 23, 2023 &nbsp; 05:23 PM</span><span>42
                                                                    Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-editor-body">
                                <div className="wide-md h-100">
                                    <div className="js-editor nk-editor-style-clean nk-editor-full" data-menubar="false"
                                        id="editor-v1">
                                        <h1>The Importance of Business Metrics in Product Design Industry</h1>
                                        <h3>Introduction</h3>
                                        <p>In the product design industry, creating a successful product is a top
                                            priority for any business. However, creating a product that sells and brings
                                            in revenue is equally important. This is where business metrics come into
                                            play. Business metrics are key performance indicators (KPIs) that measure a
                                            company's success in achieving its goals. In this blog post, we will discuss
                                            the importance of business metrics in the product design industry.</p>
                                        <h3>Understanding Customer Needs</h3>
                                        <p>One of the most important aspects of product design is understanding customer
                                            needs. Business metrics help companies gather data on customer behavior,
                                            such as purchase history, product reviews, and customer feedback. By
                                            analyzing this data, companies can determine what products are in demand and
                                            what features customers are looking for. This helps companies design
                                            products that meet customer needs, which can increase sales and customer
                                            satisfaction.</p>
                                        <h3>Measuring Product Performance</h3>
                                        <p>Business metrics also help companies measure the performance of their
                                            products. Metrics such as sales revenue, profit margin, and customer
                                            retention rate can provide insight into how well a product is performing in
                                            the market. By analyzing these metrics, companies can make data-driven
                                            decisions about product design, pricing, and marketing strategies. This can
                                            increase profitability and help companies stay competitive in the market.
                                        </p>
                                        <h3>Identifying Areas for Improvement</h3>
                                        <p>Finally, business metrics can help companies identify areas for improvement
                                            in their product design process. Metrics such as product development cycle
                                            time, defect rate, and customer satisfaction can help companies pinpoint
                                            areas where they can improve their processes. By addressing these areas,
                                            companies can streamline their product design process and improve the
                                            quality of their products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default EditPost;
