
import React from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';

function NewPost() {

    return (
        <div className="nk-main">
            <Sidebar />
            <div className="nk-wrap">
                <Header />
                <div className="nk-content nk-content-empty">
                    <div className="nk-editor">
                        <div className="nk-editor-header">
                            <div className="nk-editor-title">
                                <h4 className="me-3 mb-0 line-clamp-1">2023-02-03 Untitled</h4>
                                <ul className="d-inline-flex align-item-center">
                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                        className="icon ni ni-pen"></em></button></li>
                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                        className="icon ni ni-star"></em></button></li>
                                    <li className="d-xl-none me-n1">
                                        <div className="dropdown"><button className="btn btn-sm btn-icon btn-zoom"
                                            type="button" data-bs-toggle="dropdown"><em
                                                className="icon ni ni-menu-alt-r"></em></button>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                                <div className="dropdown-content">
                                                    <ul className="link-list link-list-hover-bg-primary link-list-md">
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-docs"></em><span>Docs</span></a>
                                                        </li>
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-text"></em><span>Text</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-editor-tools d-none d-xl-flex">
                                <ul className="d-inline-flex gap gx-3 gx-lg-4 pe-4 pe-lg-5">
                                    <li><span className="sub-text text-nowrap">Words <span
                                        className="text-dark">25</span></span></li>
                                    <li><span className="sub-text text-nowrap">Characters <span
                                        className="text-dark">84</span></span></li>
                                </ul>
                                <ul className="d-inline-flex gap gx-3">
                                    <li>
                                        <div className="dropdown"><button className="btn btn-md btn-light rounded-pill"
                                            type="button" data-bs-toggle="dropdown"><span>Export</span><em
                                                className="icon ni ni-chevron-down"></em></button>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                                <div className="dropdown-content">
                                                    <ul className="link-list link-list-hover-bg-primary link-list-md">
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-doc"></em><span>Docs</span></a>
                                                        </li>
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-text"></em><span>Text</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li><button className="btn btn-md btn-primary rounded-pill" type="button"> Save
                                    </button></li>
                                </ul>
                            </div>
                        </div>
                        <div className="nk-editor-main">
                            <div className="nk-editor-base">
                                <ul className="nav nav-tabs nav-sm nav-tabs-s1 px-3">
                                    <li className="nav-item"><button className="nav-link active" type="button"
                                        data-bs-toggle="tab" data-bs-target="#AI-Writer">AI Writer</button></li>
                                    <li className="nav-item"><button className="nav-link" type="button" data-bs-toggle="tab"
                                        data-bs-target="#History">History</button></li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="AI-Writer">
                                        <div className="px-3 pt-4 pb-3">
                                            <h6 className="fw-normal">What do you want to generate?</h6>
                                        </div>
                                        <ul className="d-flex flex-column">
                                            <li><a className="bg-opacity-80 hover-bg-lighter px-3 py-2 d-block"
                                                href="https://copygen.themenio.com/dashboard/document-editor-generate.html">
                                                <div className="d-flex gap g-2"><em
                                                    className="icon fs-4 ni ni-file-text-fill text-warning"></em>
                                                    <div className="">
                                                        <h6 className="mb-1">Blog Ideas</h6>
                                                        <p className="small text-light">Write blog ideas for
                                                            business engagements.</p>
                                                    </div>
                                                </div>
                                            </a></li>
                                            <li><a className="bg-opacity-80 hover-bg-lighter px-3 py-2 d-block"
                                                href="https://copygen.themenio.com/dashboard/document-editor-generate.html">
                                                <div className="d-flex gap g-2"><em
                                                    className="icon fs-4 ni ni-list-thumb-fill text-info"></em>
                                                    <div className="">
                                                        <h6 className="mb-1">Blog Post Outline</h6>
                                                        <p className="small text-light">Create blog outlines that
                                                            performs best.</p>
                                                    </div>
                                                </div>
                                            </a></li>
                                            <li><a className="bg-opacity-80 hover-bg-lighter px-3 py-2 d-block"
                                                href="https://copygen.themenio.com/dashboard/document-editor-generate.html">
                                                <div className="d-flex gap g-2"><em
                                                    className="icon fs-4 ni ni-pen-fill text-primary"></em>
                                                    <div className="">
                                                        <h6 className="mb-1">Social Media Posts</h6>
                                                        <p className="small text-light">Write blog ideas for
                                                            business engagements.</p>
                                                    </div>
                                                </div>
                                            </a></li>
                                            <li><a className="bg-opacity-80 hover-bg-lighter px-3 py-2 d-block"
                                                href="https://copygen.themenio.com/dashboard/document-editor-generate.html">
                                                <div className="d-flex gap g-2"><em
                                                    className="icon fs-4 ni ni-laptop text-blue"></em>
                                                    <div className="">
                                                        <h6 className="mb-1">Website Headlines/Copy</h6>
                                                        <p className="small text-light">Professional copy for your
                                                            website</p>
                                                    </div>
                                                </div>
                                            </a></li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane fade" id="History">
                                        <div className="px-3 py-4">
                                            <div className="d-flex flex-wrap justify-content-between mb-3">
                                                <h6 className="mb-0 me-3">Generation History</h6>
                                                <div className="fs-11px"><span className="text-dark">1763</span> words left.
                                                    <a href="#">Go Unlimited</a></div>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-2">
                                                                <div
                                                                    className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                    <em className="icon ni ni-pen-fill"></em><span>Social
                                                                        Media Post</span></div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button
                                                                        className="btn btn-sm btn-icon btn-zoom"><em
                                                                            className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPost01"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-dark" id="SocialMediaPost01">Hey
                                                                everyone! Have you met ChatGPT? As an AI language
                                                                model, I'm trained to answer your questions and have
                                                                a conversation with you! Ask me anything, and let's
                                                                get chatting! 😊</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>Feb 23, 2023 &nbsp; 05:23 PM</span><span>42
                                                                    Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-2">
                                                                <div
                                                                    className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                    <em className="icon ni ni-pen-fill"></em><span>Social
                                                                        Media Post</span></div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button
                                                                        className="btn btn-sm btn-icon btn-zoom"><em
                                                                            className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPost02"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-dark" id="SocialMediaPost02">Are you
                                                                tired of Googling for answers? Meet ChatGPT - your
                                                                one-stop solution for all your queries! I'm an AI
                                                                language model trained to provide you with accurate
                                                                and informative responses. Try me out! 💬</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>Feb 23, 2023 &nbsp; 05:23 PM</span><span>42
                                                                    Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-primary-soft border-0 shadow-none">
                                                        <div className="p-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between mb-2">
                                                                <div
                                                                    className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                    <em className="icon ni ni-pen-fill"></em><span>Social
                                                                        Media Post</span></div>
                                                                <ul className="d-flex align-items-center gap gx-1">
                                                                    <li><button
                                                                        className="btn btn-sm btn-icon btn-zoom"><em
                                                                            className="icon ni ni-chevrons-left"></em></button>
                                                                    </li>
                                                                    <li><button className="js-copy has-tooltip"
                                                                        data-clipboard-target="#SocialMediaPost03"></button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-dark" id="SocialMediaPost03">OpenAI is
                                                                changing the game for data-driven innovation. With
                                                                its cutting-edge technology, we can harness the
                                                                power of data in ways we never thought possible.</p>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center fs-11px text-light">
                                                                <span>Feb 23, 2023 &nbsp; 05:23 PM</span><span>42
                                                                    Words</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-editor-body">
                                <div className="wide-md h-100">
                                    <div className="js-editor nk-editor-style-clean nk-editor-full" data-menubar="false"
                                        id="editor-v1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default NewPost;
