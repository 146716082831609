
import React from 'react';

function GeneratePost() {

    return (
        <>
            <h1>Generate Post</h1>
        </>
    );
}

export default GeneratePost;
