function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';



export const PATH_AUTH = {
    root: ROOTS_AUTH,
    signIn: path(ROOTS_AUTH, '/signin'),
    signUp: path(ROOTS_AUTH, '/signup'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    newPost: path(ROOTS_DASHBOARD, 'new-post'),
    generatePost: path(ROOTS_DASHBOARD, 'generate-post'),
    editPost: path(ROOTS_DASHBOARD, 'edit-post'),
    pricingPlan: path(ROOTS_DASHBOARD, 'pricing-plans'),
    profile: path(ROOTS_DASHBOARD, 'profile'),
}

export const PATH_DOCS = ROOTS_DASHBOARD;