
import React, { useContext, useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import customAxios from '../utils/customAxios';

function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const checkAuth = async () => {
        try {
            // Make an authenticated API request to get user data
            await customAxios.get('/account/info')
                .then(resObj => {
                    if (resObj.data.code === 200) {
                        setFirstName(resObj.data.data.firstName)
                        setLastName(resObj.data.data.lastName)
                        setEmail(resObj.data.data.email)
                    } else {
                        console.log(resObj.data.data)
                        // window.location.href = PATH_AUTH.signIn;
                    }
                }).catch(err => {
                    console.log(err.message)
                });
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);


    return (
        <>
            <div className="nk-main">
                <Sidebar />
                <div className="nk-wrap">
                    <Header />
                    {/* <h1>Loeading...</h1> */}
                    <div className="nk-content">
                        <div className="container-xl">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head nk-page-head">
                                        <div className="nk-block-head-between">
                                            <div className="nk-block-head-content">
                                                <h2 className="display-6">Personal Account</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <ul className="nav nav-tabs mb-3 nav-tabs-s1">
                                            <li className="nav-item">
                                                <button className="nav-link active" type="button" data-bs-toggle="tab" data-bs-target="#profile-tab-pane">Profile</button>
                                            </li>
                                            <li className="nav-item">
                                                <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#payment-billing-tab-pane">
                                                    Payment &amp; Billing</button>
                                            </li>
                                            <li className="nav-item">
                                                <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#payment-billing-2-tab-pane">
                                                    Payment &amp; Billing
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="profile-tab-pane">
                                                {firstName === '' ?
                                                    <h4 className='text-center'>Loading....</h4>
                                                    :
                                                    <>

                                                        <div
                                                            className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                                                            <h5>Personal Details</h5>
                                                            <a className="link link-primary fw-normal" href="#">Edit Profile</a>
                                                        </div>
                                                        <table className="table table-flush table-middle mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tb-col">
                                                                        <span className="fs-15px text-light">Full Name</span>
                                                                    </td>
                                                                    <td className="tb-col">
                                                                        <span className="fs-15px text-base">{firstName + ' ' + lastName}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="tb-col">
                                                                        <span className="fs-15px text-light">Email</span>
                                                                    </td>
                                                                    <td className="tb-col">
                                                                        <span className="fs-15px text-base">{email}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="tb-col">
                                                                        <span className="fs-15px text-light">Password</span>
                                                                    </td>
                                                                    <td className="tb-col">
                                                                        <span className="fs-15px text-base">
                                                                            <a className="link link-primary fw-normal" href="#">
                                                                                Change Password
                                                                            </a>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="payment-billing-tab-pane">
                                                <div
                                                    className="d-flex flex-wrap align-items-center justify-content-between border-bottom border-light mt-5 mb-4 pb-1">
                                                    <h5 className="mb-0">Your Subscription</h5>
                                                    <ul className="d-flex gap gx-4">
                                                        <li><a className="link link-danger fw-normal" data-bs-toggle="modal"
                                                            href="#cancelSubscriptionModal">Cancel Subscription</a></li>
                                                        <li><a className="link link-primary fw-normal" data-bs-toggle="modal"
                                                            href="#changePlanModal">Change Plan</a></li>
                                                    </ul>
                                                </div>
                                                <div className="alert alert-warning alert-dismissible fade show mb-4 rounded-6"
                                                    role="alert">
                                                    <p className="small mb-0">Save big up to 75% on your upgrade to our
                                                        <strong><a className="alert-link" href="#">Enterprise plan</a></strong>
                                                        and enjoy premium features at a fraction of the cost!</p>
                                                    <div
                                                        className="d-inline-flex position-absolute end-0 top-50 translate-middle-y me-2">
                                                        <button type="button"
                                                            className="btn btn-xs btn-icon btn-warning rounded-pill"
                                                            data-bs-dismiss="alert"><em
                                                                className="icon ni ni-cross"></em></button></div>
                                                </div>
                                                <div className="row g-gs">
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="card shadow-none">
                                                            <div className="card-body">
                                                                <div className="text-light mb-2">Plan</div>
                                                                <h3 className="fw-normal">Professional Plan</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="card shadow-none">
                                                            <div className="card-body">
                                                                <div className="text-light mb-2">Recurring Payment</div>
                                                                <h3 className="fw-normal">$23/Month</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="card shadow-none">
                                                            <div className="card-body">
                                                                <div className="text-light mb-2">Next Due Date</div>
                                                                <h3 className="fw-normal">Mar 15, 2023</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="card shadow-none">
                                                            <div className="card-body">
                                                                <div className="text-light mb-2">Payment Method</div>
                                                                <div className="d-flex align-items-center"><img
                                                                    src="https://copygen.themenio.com/dashboard/images//icons/paypal.png"
                                                                    alt="" className="icon" />
                                                                    <h3 className="fw-normal ms-2">PayPal</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 mb-4 pb-2">
                                                    <h5>Billing History</h5>
                                                </div>
                                                <div className="card">
                                                    <table className="table table-middle mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="tb-col">
                                                                    <div className="fs-13px text-base">Subscription</div>
                                                                </th>
                                                                <th className="tb-col tb-col-md">
                                                                    <div className="fs-13px text-base">Payment Date</div>
                                                                </th>
                                                                <th className="tb-col tb-col-sm">
                                                                    <div className="fs-13px text-base">Total</div>
                                                                </th>
                                                                <th className="tb-col tb-col-sm">
                                                                    <div className="fs-13px text-base">Status</div>
                                                                </th>
                                                                <th className="tb-col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="tb-col">
                                                                    <div className="caption-text">Starter - 12 Months <div
                                                                        className="d-sm-none dot bg-success"></div>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col tb-col-md">
                                                                    <div
                                                                        className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                        <span>Feb 15,2023 </span> <span>02:31 PM</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col tb-col-sm">
                                                                    <div className="fs-6 text-light">$23.00</div>
                                                                </td>
                                                                <td className="tb-col tb-col-sm">
                                                                    <div
                                                                        className="badge text-bg-success-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                                                                        Paid</div>
                                                                </td>
                                                                <td className="tb-col tb-col-end"><a href="#" className="link">Get
                                                                    Invoice</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="tb-col">
                                                                    <div className="caption-text">Starter - 12 Months <div
                                                                        className="d-sm-none dot bg-warning"></div>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col tb-col-md">
                                                                    <div
                                                                        className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                        <span>Feb 15,2023 </span> <span>02:31 PM</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col tb-col-sm">
                                                                    <div className="fs-6 text-light">$23.00</div>
                                                                </td>
                                                                <td className="tb-col tb-col-sm">
                                                                    <div
                                                                        className="badge text-bg-warning-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                                                                        Faild</div>
                                                                </td>
                                                                <td className="tb-col tb-col-end"><a href="#" className="link">Get
                                                                    Invoice</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="payment-billing-2-tab-pane">
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                                                    <h5>Subscription</h5>
                                                </div>
                                                <div className="pt-4">
                                                    <h3 className="fw-normal">Basic Plan <span
                                                        className="badge border border-light text-light rounded-pill ms-1">Free</span>
                                                    </h3>
                                                    <div className="sub-text">5000 words generation with access of 10 templates.
                                                    </div>
                                                    <div className="progress progress-md bg-primary bg-opacity-10 mt-3">
                                                        <div className="progress-bar bg-primary" data-progress="25%"></div>
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center mt-2">
                                                        <div className="caption-text">940 <span className="text-light">of 5000 words
                                                            used.</span></div>
                                                        <div className="sub-text text-dark">To increase your limit, check our <a
                                                            href="#">Pricing &amp; Plans</a>.</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                                                    <h5>Our Available Plans</h5><a className="link link-primary fw-normal"
                                                        href="#">See All</a>
                                                </div>
                                                <div className="pt-4">
                                                    <h3 className="fw-normal">Pricing Plans</h3>
                                                    <div className="sub-text">Generate unlimited copy 10X faster with our cost
                                                        effective plan to write blog posts, social media ads and many more.
                                                    </div>
                                                    <div className="pricing-toggle-wrap mt-4 mb-4"><button
                                                        className="pricing-toggle-button active"
                                                        data-target="monthly">Monthly</button><button
                                                            className="pricing-toggle-button" data-target="yearly">Yearly (Save
                                                            25%)</button></div>
                                                </div>
                                                <div className="row g-gs">
                                                    <div className="col-md-6">
                                                        <div
                                                            className="pricing rounded pricing-featured bg-gradient-custom gradient-start-primary-light gradient-middle-primary-light gradient-end-blue-light gradient-angle-90">
                                                            <div className="pricing-content">
                                                                <h5 className="fw-normal text-dark">Professional</h5>
                                                                <h2
                                                                    className="mb-3 d-inline-block text-gradient-custom gradient-start-primary-light gradient-middle-primary-light gradient-end-blue-light gradient-angle-90">
                                                                    Unlimited Words</h2>
                                                                <div className="pricing-price-wrap">
                                                                    <div
                                                                        className="pricing-price pricing-toggle-content monthly active">
                                                                        <h3 className="display-1 mb-3 fw-semibold">$48 <span
                                                                            className="caption-text text-light fw-normal"> /
                                                                            month</span></h3>
                                                                    </div>
                                                                    <div
                                                                        className="pricing-price pricing-toggle-content yearly">
                                                                        <h3 className="display-1 mb-3 fw-semibold">$500 <span
                                                                            className="caption-text text-light fw-normal"> /
                                                                            year</span></h3>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <div className="pricing-toggle-content monthly active">
                                                                        <button className="btn btn-gradient w-100">Upgrade
                                                                            Now</button></div>
                                                                    <div className="pricing-toggle-content yearly"><button
                                                                        className="btn btn-gradient w-100">Upgrade
                                                                        Now</button></div>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center text-center text-dark fs-12px lh-lg fst-italic mt-1">
                                                                        <svg width="13" height="13" viewBox="0 0 13 13"
                                                                            className="text-danger" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.5 2.375V10.625"
                                                                                stroke="currentColor" stroke-width="1.25"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M2.9281 4.4375L10.0719 8.5625"
                                                                                stroke="currentColor" stroke-width="1.25"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M2.9281 8.5625L10.0719 4.4375"
                                                                                stroke="currentColor" stroke-width="1.25"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                        </svg><span className="ms-1">Cancel Anytime</span></div>
                                                                </div>
                                                                <ul className="pricing-features">
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle-fill"></em><span>Unlimited
                                                                            words generation</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle-fill"></em><span>Access
                                                                            to all templates for free</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle-fill"></em><span>No
                                                                            project limitation</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle-fill"></em><span>30
                                                                            days of AI generation history</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle-fill"></em><span>Wordpress
                                                                            plugin integration</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="pricing rounded">
                                                            <div className="pricing-content">
                                                                <h5 className="fw-normal text-light">Enterprise</h5>
                                                                <h2 className="mb-3">Customized Plan</h2>
                                                                <div className="pricing-price-wrap">
                                                                    <div
                                                                        className="pricing-price pricing-toggle-content monthly active">
                                                                        <h3 className="display-1 mb-3 fw-semibold">$225 <span
                                                                            className="caption-text text-light fw-normal"> /
                                                                            month</span></h3>
                                                                    </div>
                                                                    <div
                                                                        className="pricing-price pricing-toggle-content yearly">
                                                                        <h3 className="display-1 mb-3 fw-semibold">$2500 <span
                                                                            className="caption-text text-light fw-normal"> /
                                                                            year</span></h3>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <div className="pricing-toggle-content monthly active">
                                                                        <button className="btn btn-outline-light w-100">Upgrade
                                                                            Now</button></div>
                                                                    <div className="pricing-toggle-content yearly"><button
                                                                        className="btn btn-outline-light w-100">Contact
                                                                        Sales</button></div>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center text-center text-light fs-12px lh-lg fst-italic mt-1">
                                                                        <svg width="13" height="13" viewBox="0 0 13 13"
                                                                            className="text-danger" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.5 2.375V10.625"
                                                                                stroke="currentColor" stroke-width="1.25"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M2.9281 4.4375L10.0719 8.5625"
                                                                                stroke="currentColor" stroke-width="1.25"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M2.9281 8.5625L10.0719 4.4375"
                                                                                stroke="currentColor" stroke-width="1.25"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                        </svg><span className="ms-1">Cancel Anytime</span></div>
                                                                </div>
                                                                <ul className="pricing-features">
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle"></em><span>Unlimited
                                                                            words generation</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle"></em><span>Access
                                                                            to all templates for free</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle"></em><span>No
                                                                            project limitation</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle"></em><span>30
                                                                            days of AI generation history</span></li>
                                                                    <li><em
                                                                        className="icon text-primary ni ni-check-circle"></em><span>Wordpress
                                                                            plugin integration</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Profile;
