
import React from 'react';

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

function Page404() {

    return (
        <>

            <main className="nk-pages">
                <section className="section section-sm section-bottom-0 pb-5 has-mask">
                    <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row g-gs justify-content-center">
                                <div className="col-md-9 col-lg-7 col-xl-6 col-xxl-5">
                                    <div className="block-gfx">
                                        <img
                                            className="w-100 rounded-4"
                                            src="/assets/images/404.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="block-text text-center mt-4">
                                        <h3 className="title">Oops! Why you’re here?</h3>
                                        <p>
                                            We are very sorry for inconvenience. It looks like you’re
                                            try to access a page that either has been deleted or never
                                            existed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Page404;
