import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { CgLoadbarAlt } from 'react-icons/cg';

import { PATH_AUTH } from '../../routes/paths';
import axios from 'axios';
import { HOST_API } from '../../config';
import capitalizeFirstLetter from './utils';

function SignUp() {
    const [submitBtn, setSubmitBtn] = useState('Sign Up');
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtn(<CgLoadbarAlt />)
        setSubmitBtnDisabled(true)

        try {

            axios.post(HOST_API + "account/signup", formData)
                .then(res => {
                    console.log(res);
                    const resObj = res.data
                    if (resObj.code === 200) {
                        // console.log(resObj.data.token)
                        // localStorage.setItem("user", resObj.data.token)
                        setSuccess(resObj.msg)

                        setSubmitBtn('Sign Up')
                        setSubmitBtnDisabled(false)

                        // Reset the form
                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            password: ''
                        });
                        // navigate(PATH_AUTH.signIn, { replace: true })
                    } else {
                        setSubmitBtn('Sign Up')
                        setSubmitBtnDisabled(false)
                        setError(resObj.msg)
                    }

                }).catch(err => {
                    setError("Network Error")
                    console.log(err)
                    console.log('Network Error')
                    setSubmitBtn('Sign Up')
                    setSubmitBtnDisabled(false)
                })

        } catch (error) {
            // Handle any errors that may occur during the request
            console.error('Error:', error);
            setSubmitBtn('Sign Up')
            setSubmitBtnDisabled(false)
        }
    };

    return (
        <>
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                    <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
                    <div className="container p-2 p-sm-4 mt-auto">
                        <div className="text-center pt-5 pb-5">
                            <Link
                                to=""
                                className="logo-link"
                            >
                                <div className="logo-wrap">
                                    <img
                                        className="logo-img logo-light"
                                        src="/assets/images/logo.png"
                                        alt=""
                                    />
                                    <img
                                        className="logo-img logo-dark"
                                        src="/assets/images/logo-dark.png"
                                        alt=""
                                    />
                                    <img
                                        className="logo-img logo-icon"
                                        src="/assets/images/logo-icon.png"
                                        alt=""
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4">
                                <div className="nk-block">
                                    <div className="nk-block-head text-center mb-4 pb-2">
                                        <div className="nk-block-head-content">
                                            <h1 className="nk-block-title mb-1">Sign Up to Your Account</h1>
                                            <p className="small">
                                                Sign in to your account to customize your content
                                                generation settings and view your history.
                                            </p>
                                        </div>
                                    </div>
                                    <form noValidate onSubmit={handleSubmit}>
                                        <div className="row gy-3">
                                            <div className="col-12">
                                                {error && <div className="alert alert-danger" role="alert"><p>{capitalizeFirstLetter(error)}</p></div>}
                                                {success && <div className="alert alert-success" role="alert"><p>{capitalizeFirstLetter(success)}</p></div>}
                                                <div className="form-group">
                                                    <label className="form-label" >First Name</label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            className="form-control"
                                                            placeholder="Enter First Name"
                                                            value={formData.firstName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label" >Last Name</label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Email Address
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className="form-control"
                                                            placeholder="Enter Email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Password
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            className="form-control"
                                                            placeholder="Enter Password"
                                                            value={formData.password}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        type="submit"
                                                        id="submit-btn"
                                                        disabled={submitBtnDisabled}
                                                    >
                                                        {submitBtn}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center mt-3">
                                        <p className="small">
                                            Don’t have an account? &nbsp;
                                            <Link to={PATH_AUTH.signIn}>Sign In</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer text-center">
                        &copy; 2023 All Rights Reserved to &nbsp;<a href="https://strano.io">Strano</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUp;
